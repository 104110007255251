import { DataSet } from "vis-data";
import { Timeline } from "vis-timeline/esnext";

class App {
    constructor(_window: Window) {
        this.window = _window;
        this.localStorage = _window.localStorage;
        this.document = _window.document;

        this.document.addEventListener("DOMContentLoaded", this.setDataFromLocalStorage);
        (this.document.querySelector('form') as any).onchange = this.formChanged;
        var formEl = (this.document.querySelector('form') as HTMLFormElement);
        formEl.onsubmit = this.onSubmit;
    }

    public formChanged = async (event: any) => {
        this.localStorage.setItem(event.target.name, event.target.value)
    }

    public setDataFromLocalStorage = async (event: any) => {
        (this.document.querySelector(".heartUri") as any).value = this.localStorage.getItem("heartUri");
        (this.document.querySelector(".token") as any).value = this.localStorage.getItem("token");
        (this.document.querySelector(".params") as any).value = this.localStorage.getItem("params");

    }
    public onSubmit = async (event: any) => {
        var settingsDiv: any = this.document.querySelector(".settings");
        settingsDiv.style.display = "none"

        var interval = (this.document.querySelector(".interval") as any).value * 1000

        event.preventDefault();

        this.fetchData().then(data => {
            if (!this.initDone) {
                this.init()
                this.initDone = true;
            }
            this.add(data[0])
            this.add(data[1])
        });

        setInterval(() => {
            this.fetchData().then(data => {
                if (!this.initDone) {
                    this.init()
                    this.initDone = true;
                }
                this.add(data[0])
                this.add(data[1])
            });
        }, interval);
    }

    public window: Window;
    public document: Document;
    public localStorage: Storage;

    public runTaskMap: any = {}
    public taskRunMap: any = {}
    public groups: any = {}
    public subgroups: any = {}
    public idc = 1;
    public ii: any = [];
    public localItems: any = new DataSet();
    public groupsVis: any = new DataSet();
    public groupMapping = {}
    public store: any = {
        groups: [],
        runningRuns: {},
        runs: []
    }
    public initDone = false;
    public lastCall: any = []
    public timeline: any = null;

    public init = () => {

        var container: any = this.document.getElementById('mytimeline');
        container.innerHTML = "";
        var options = {
            stack: false,
            multiselect: false,
            groupOrder: 'content'  // groupOrder can be a property name or a sorting function
        };
        this.timeline = new Timeline(container, this.localItems, this.groupsVis, options);
        this.timeline.on('select', (properties: any) => {

            var taskid = this.runTaskMap[properties.items[0]];
            this.timeline.itemsData.forEach((e: any, i: any) => {
                this.timeline.itemSet.items.u
                if (e.taskid == taskid) {
                    e.className = "selected " + e.status
                } else {
                    e.className = e.status
                }
                this.timeline.itemsData.update(e)
            })
        });
    }

    public add = (data: any) => {
        data.forEach((d: any) => {
            if (this.taskRunMap[d.taskId] == null) {
                this.taskRunMap[d.taskId] = []
            }
            this.taskRunMap[d.taskId].push(d.runId)

            this.runTaskMap[d.runId] = d.taskId;

            if (this.groups[d.agentDetails.instance.name] == undefined) {
                this.groups[d.agentDetails.instance.name] = {}
            }
            this.groups[d.agentDetails.instance.name][d.processName] = d.processName;
        });


        Object.keys(this.groups).forEach((s: any) => {
            Object.keys(this.groups[s]).forEach((sg: any) => {
                if (!this.store.groups.includes(s + "-" + sg)) {
                    this.store.groups.push(s + "-" + sg)

                    var result = {
                        id: s + "-" + sg,
                        treeLevel: 2,
                        content: sg
                    }
                    this.groupsVis.add([result])
                }
            })
        });

        Object.keys(this.groups).forEach((agentid: any) => {
            var result = {
                id: agentid,
                content: agentid,
                treeLevel: 1,
                nestedGroups: new Array<any>()
            }
            Object.keys(this.groups[agentid]).forEach((subgroupName: any) => {
                result.nestedGroups.push(agentid + "-" + subgroupName)
            })
            if (!this.store.groups.includes(agentid)) {
                this.groupsVis.add([result])
                this.store.groups.push(agentid)
            } else {
                var e = this.groupsVis.get(agentid);
                e.nestedGroups = result.nestedGroups
                this.groupsVis.update(e);
            }
        });
        let added = 0;
        data.forEach((d: any) => {

            if (!this.store.runs.includes(d.runId)) {
                this.localItems.add(this.timelineItem(d));
                added++;
                if (d.result == undefined) {
                    this.store.runningRuns[d.runId] = d.runId;
                }
                this.store.runs.push(d.runId)
            } else if (Object.keys(this.store.runningRuns).includes(d.runId.toString())) {
                this.localItems.update(this.timelineItem(d));

                delete this.store.runs[d.runId.toString()]
            }

        });
        this.timeline.redraw();
        console.log('Added: ' + added);

        // Create a timeline

    }

    timelineItem = (d: any) => {
        var start = new Date(d.startedAt);
        var end = new Date(d.stoppedAt);
        var color = d.result == "Failed" ? "red" : (d.result == "Error" ? "orange" : "green");

        return {
            id: d.runId,
            taskid: d.taskId,
            group: d.agentDetails.instance.name + "-" + d.processName,
            content: d?.processName + " " + d.taskId, // Remark or any other column that you wish to display
            start: start,
            end: end,
            className: d.result,
            status: d.result,
            title: `Task ${d.taskId}, ${d.result}<br />Run ${d.runId}<br />${d.remark}` // Tooltip text
        }
    }

    fetchData = async () => {
        const heart = this.localStorage.getItem("heartUri");
        const tasksUri = heart + "/api/tasks" + this.localStorage.getItem("params");
        const tasksUriRunning = heart + "/api/tasks?filters=status==Running";
        const agentsUri = heart + "/api/agents";
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': 'Bearer ' + this.localStorage.getItem("token")
        };

        try {
            const [taskResponse, agentResponse, tasksUriRunningResponse] = await Promise.all([
                fetch(tasksUri, { headers: headers, mode: "cors" }).then(response => response.json()),
                fetch(agentsUri, { headers: headers , mode: "cors"}).then(response => response.json()),
                fetch(tasksUriRunning, { headers: headers, mode: "cors" }).then(response => response.json()).catch(err=> [])
            ]);
            if (taskResponse) {
                let allRunningRuns = [];
                const allRuns = taskResponse.map((task: any) => {
                    const processName = task.process.name;
                    const runs = task.runs.map((run: any) => {
                        let agentDetails = agentResponse.find((agent: any) => agent.agentId === run.agentId);

                        run.processName = processName;
                        run.agentDetails = agentDetails ? {
                            username: agentDetails.username,
                            status: agentDetails.status,
                            agentAttendanceType: agentDetails.agentAttendanceType,
                            instance: agentDetails.instance
                        } : {
                            instance: {
                                name: "Deleted"
                            }
                        };
                        run.remark = run.remark?.split("\n")[0].trim();
                        return run;
                    });
                    return runs;
                }).flat();

                if (tasksUriRunningResponse) {
                    allRunningRuns = tasksUriRunningResponse.map((task: any) => {
                        const processName = task.process.name;
                        const runs = task.runs.map((run: any) => {
                            let agentDetails = agentResponse.find((agent: any) => agent.agentId === run.agentId);

                            run.processName = processName;
                            run.agentDetails = agentDetails ? {
                                username: agentDetails.username,
                                status: agentDetails.status,
                                agentAttendanceType: agentDetails.agentAttendanceType,
                                instance: agentDetails.instance
                            } : {
                                instance: {
                                    name: "Deleted"
                                }
                            };
                            run.remark = run.remark?.split("\n")[0].trim();
                            return run;
                        });
                        return runs;
                    }).flat();
                }

                console.log("Data fetched and processed successfully.");
                return [allRuns, allRunningRuns];
            }

            console.log("No 'runs' data found in the response or the response is null.");

            return [];
        } catch (error) {
            console.error("Failed to fetch data:", error);
            return [];
        }
    }
}



class Helper {
    public static parseCSV = (text: string) => {
        // Regular expression to split the line with semicolon that are inside the double quotes
        const regex = /"([^"]*)";?/g;
        let columns = [];
        let match;
        while (match = regex.exec(text)) {
            columns.push(match[1].replace(/""/g, '"'));  // Handle escaped double quotes
        }
        return columns;
    }
}


(window as any).app = new App(window);
